import React from 'react'

import './InformationSecurityPolicy.scss'

const InformationSecurityPolicy = () => {
    return (
        <>
            <div className='main-content'>
                <div className='title'>Information Security Policy</div>
                <p>
                    Solv information Security Policy defines the fundamental principles for the protection of Solv’s information assets and resources, the proper controls needed to ensure compliance with internal policies and external regulations, and to uphold Solv’s reputation with its clients. All Solv employees are responsible for ensuring compliance with this policy to ensure that confidentiality, integrity and availability of information related to Solv is not compromised. This shall be achieved by:
                </p>
                <p>
                    1. Establishing and maintaining an Information Security Program consisting of an Information Security Policy document, supporting Procedures and a Risk Management Framework.
                </p>
                <p>
                    2. Ensuring that policies and related procedures align themselves to the Risk Management Framework
                </p>
                <p>
                    3. Deploying appropriate technology, resources and infrastructure at Solv to provide the required level of protection.
                </p>
                <p>
                    4. Educating and ensuring employees, affiliates and contracted third-parties of Solv are aware of the information security policy, its supporting normative standards, and guidelines.
                </p>
                <p>
                    5. Enabling access to information and information technology resources in a controlled, monitored, and authorised manner as per the job function and on a need-to-know and need-to-perform criteria.
                </p>
                <p>
                    6. Improving the effectiveness of the Information Security program by performing constant monitoring, review, exception-reporting and taking appropriate corrective and preventive actions.
                </p>
                <p>
                    7. Ensuring that compliance violations are documented, reported and investigated.
                </p>
                <p>
                    8. Creating and maintaining a security conscious culture within the organisation.
                </p>
                <p>
                    9. Integrating information security management system requirements into organisations processes.
                </p>
                <p>
                    10. Continuity of Information Security during BCP scenarios.
                </p>
            </div>
        </>
    )
}

export default InformationSecurityPolicy
